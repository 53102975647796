import React from "react"
import { graphql, Link } from "gatsby"
import tw, { styled, css } from "twin.macro"
import "styled-components/macro"
import _ from "lodash"
import moment from "moment"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TZ } from "../constants"

import Layout from "../components/Layout"
import Header from "../components/Header"
import DawgsImgBackup from "../components/DawgsImgBackup"

const Wrapper = styled.div`
  ${tw`w-full text-lg relative p-5`}

  ${({ $filtered }) => $filtered && tw`sm:p-0`}
`

const ColoredLink = styled(Link)`
  ${tw`hover:text-[#EC1F27] inline-block max-w-max`}
`

//the padding bottom of 50% is how the aspect ratio for this image container link (i.e. height is half the width)
const ImageContainer = styled.div`
  ${({ $filtered }) => $filtered && tw`relative pb-[50%]`}
`

const Image = styled(GatsbyImage)`
  ${({ $filtered }) => $filtered && tw`w-full h-full absolute object-cover`}
`

const PostsContainer = styled.div`
  ${({ showAll }) =>
    showAll &&
    tw`
      grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3
      gap-5 mt-5
    `}

  > h3 {
    ${tw`font-medium`}
  }
`

const Article = styled.article`
  ${tw`mb-5 sm:mb-10 bg-white flex flex-col`}

  ${({ $filtered }) =>
    $filtered &&
    css`
      ${tw`p-5 sm:p-8`}

      > *:nth-child(1) {
        ${tw`order-3 mb-8`}
      }

      > *:nth-child(2) {
        ${tw`order-1 pt-0`}
      }

      > *:nth-child(3) {
        ${tw`order-2`}
      }

      > *:nth-child(4) {
        ${tw`order-4`}
      }

      > *:nth-child(5) {
        ${tw`order-5 text-sm`}
      }
    `}
`

const ArticleLink = styled(ColoredLink)`
  ${tw`font-medium pt-6 mb-3`}
`
const ArticleMeta = styled.div`
  ${tw`mb-4 py-3 text-sm uppercase text-gray-400`}
`

const ArticleExcerpt = styled.div`
  ${tw`mb-4 pb-4 text-sm border-b-[1.5px]`}
`

const Pagination = styled.nav`
  ${tw`text-xl w-full flex justify-center px-4 pb-4`}
`

const PageLink = styled(Link)`
  ${tw`flex justify-center items-center mx-1 text-center w-10 h-10 font-semibold`}
  ${({ active }) => active && tw`border-2 border-gray-300 text-[#EC1F27]`}
  ${({ disabled }) => disabled && tw`pointer-events-none invisible`}
`
const StyledText = styled.span`
  ${tw`font-bold text-[#EC1F27]`}
`

const NewsPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title },
    },
    allNews: { edges: posts },
  },
  pageContext: {
    seoTitle,
    currentPage,
    numPages,
    activeCategories,
    categories,
    authors,
  },
  location,
}) => {
  const getUrl = (type, typeVal) => {
    let retUrl = "/news"

    if (typeVal && type) {
      retUrl += `/${type}/` + typeVal.replace(/\s+/g, "-").toLowerCase()
    }

    return retUrl
  }

  const regexTest = /\/news\/*([a-zA-Z\-]+)/g.exec(location.pathname)
  const showAll = _.isNil(regexTest)

  const type = showAll ? undefined : regexTest[1]
  let typeVal
  if (!_.isNil(type)) {
    if (type === "category") {
      typeVal = categories[0]
    } else if (type === "author") {
      typeVal = authors[0]
    }
  }

  // DOESNT APPLY AT THE MOMENT
  // let seo = seoList.find(seoData => seoData.category === "Default")
  // if (category)
  //   seo = seoList.find(seoData => seoData.category === category) || seo

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? getUrl(type, typeVal)
      : `${getUrl(type, typeVal)}/${currentPage - 1}`
  const nextPage = `${getUrl(type, typeVal)}/${currentPage + 1}`
  const maxPageNumbersVisible = 5

  let lowerPageLimit = currentPage - 2
  let upperPageLimit = currentPage + 2

  if (lowerPageLimit <= 0 || numPages <= maxPageNumbersVisible)
    lowerPageLimit = 1

  if (upperPageLimit > numPages || numPages <= maxPageNumbersVisible)
    upperPageLimit = numPages

  let pageNumbers = []
  for (let i = lowerPageLimit; i <= upperPageLimit; i++) {
    pageNumbers.push(i)
  }

  return (
    <Layout
      seoTitle={seoTitle}
      seoDescription={seo?.description}
      noBg={!showAll}
      location={location}
    >
      <Wrapper $filtered={!showAll}>
        {showAll && title && <Header title={title} />}
        <PostsContainer showAll={showAll}>
          {posts.length > 0 ? (
            posts.map(
              (
                {
                  node: {
                    excerpt,
                    fields,
                    frontmatter: { title, author, categories, date, hero },
                  },
                },
                postIdx
              ) => {
                const pageImage = _.get(hero, "pageImage")
                const formattedSlug = `/news/${moment(date)
                  .tz(TZ)
                  .format("YYYY-MM-DD")}-${_.kebabCase(title)}`
                return (
                  <Article key={postIdx} $filtered={!showAll}>
                    <Link to={formattedSlug}>
                      {pageImage ? (
                        <ImageContainer $filtered={!showAll}>
                          <Image
                            image={getImage(pageImage)}
                            $filtered={!showAll}
                            alt="Page Image"
                          />
                        </ImageContainer>
                      ) : (
                        <DawgsImgBackup aspectRatio={!showAll ? 50 : 75} />
                      )}
                    </Link>
                    <ArticleLink to={formattedSlug}>
                      <h6>{title}</h6>
                    </ArticleLink>
                    <ArticleMeta>
                      {moment(date).tz(TZ).format("dddd, MMMM D YYYY")}
                      {/* by{" "} */}
                      {/* <ColoredLink
                        tw="text-black font-semibold"
                        to={getUrl("author", author)}
                      >
                        {author}
                      </ColoredLink> */}
                    </ArticleMeta>
                    <ArticleExcerpt>
                      {excerpt}{" "}
                      <Link to={formattedSlug}>
                        <StyledText>read more</StyledText>
                      </Link>
                    </ArticleExcerpt>
                    {/* {!_.isEmpty(categories) && (
                      <div tw="uppercase">
                        <span tw="text-gray-400">Published in </span>
                        {categories.map((category, idx) => (
                          <React.Fragment key={idx}>
                            <ColoredLink
                              to={getUrl("category", category)}
                              tw="font-medium"
                            >
                              {category}
                            </ColoredLink>
                            {idx < categories.length - 1 ? ", " : " "}
                          </React.Fragment>
                        ))}
                      </div>
                    )} */}
                  </Article>
                )
              }
            )
          ) : (
            <Article>
              <h5>No news posts available, please check back in the future.</h5>
            </Article>
          )}
        </PostsContainer>
        {numPages > 1 && (
          <Pagination>
            <PageLink
              rel="prev"
              to={prevPage}
              disabled={isFirst}
              tw="mr-8 text-2xl"
            >
              &#8249;
            </PageLink>
            {pageNumbers.map((pageNo, idx) => (
              <PageLink
                key={`page-number-${pageNo}`}
                active={currentPage === pageNo}
                to={
                  pageNo === 1
                    ? getUrl(type, typeVal)
                    : `${getUrl(type, typeVal)}/${pageNo}`
                }
                tw="text-sm"
              >
                {pageNo}
              </PageLink>
            ))}
            <PageLink
              rel="next"
              to={nextPage}
              disabled={isLast}
              tw="ml-8 text-2xl"
            >
              &#8250;
            </PageLink>
          </Pagination>
        )}
      </Wrapper>
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query NewsPageQuery(
    $skip: Int!
    $limit: Int!
    $categories: [String]
    $authors: [String]
  ) {
    markdownRemark(frontmatter: { templateKey: { eq: "news-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
      }
    }
    allNews: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "news-post" }
          publish: { eq: true }
          categories: { in: $categories }
          author: { in: $authors }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            author
            categories
            hero {
              pageImage {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.33
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          excerpt(pruneLength: 300)
        }
      }
    }
  }
`
